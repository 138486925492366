import React from 'react'
import './Home.css'

function Home() {
  return (
    <>
    <section className="about-us bg-cover bg-no-repeat" style={{ backgroundImage: "url('/images/Rectangle-14753.png')" }}>
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap">
      <div className="w-full md:w-6/12">
        <div className="content md:gap-y-20  gap-y-5 flex flex-col ">
          <h2 className="text-2xl">ABOUT US</h2>
          <p className="text-left md:text-xl text-xs text-nevy-Blue">
            The Sheikha Fathima Zaraha Hospital is dedicated to advancing its heritage of leadership in healthcare by providing exceptional and compassionate service, guided by principles of inclusion and justice, while honoring the dignity of every individual.
          </p>
        </div>
        {/* <div className="flex flex-col md:flex-row  justify-between gap-5  ">
          <button className=" button-bg-color text-white py-2 px-4 rounded-xl ">Book Appointment</button>
                
        </div> */}
        <div className="flex flex-col md:flex-row justify-between gap-5">
            <button className="mt-52 md:mt-0 button-bg-color text-white py-2 px-4 rounded-xl">Book Appointment</button>
        </div>

      </div>
      <div className="w-full md:w-6/12 md:offset-1">
        <div className="image-container flex justify-center items-center">
          <img src="/images/vector.png" alt="About Us" className="bottom-img w- mt-20" />
          <img src="/images/vector2.png" alt="About Us" className="top-img w-auto absolute mt-4" />
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}

export default Home