import React from 'react'
import aboutus from '../../img/home/aboutus.png'
import aboutusbg from '../../img/home/aboutusbg.png'
import { FaRegCircleCheck } from "react-icons/fa6";

function Aboutus() {
  return (
    <>
        <div className='container mx-auto p-7'>
            <div className='flex  flex-col md:flex-row gap-12'>
                <div className='flex justify-center'>
                    <img src={aboutus} alt="" />
                </div>
                <div className='flex flex-col  justify-start text-left' >
                    <div className='flex flex-col gap-4' style={{backgroundImage: `url(${aboutusbg})`,backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'}}>
                        <p className='text-4xl text-nevyBlue font-bold'><span className='text-4xl text-yellow font-bold'>About Our</span> Hospital</p>
                        <p>
                            To operate as a world-class heart hospital, we incorporate
                            the latest technological advances and ethical practices to
                            provide quality heart care at a reasonable cost.
                        </p>
                        <div className='flex items-center gap-3 '>
                            <FaRegCircleCheck  className='text-nevyBlue text-xl'/>
                            <p  className='font-bold'>Advanced Technology</p>
                        </div>
                        <div className='flex items-center gap-3 '>
                            <FaRegCircleCheck  className='text-nevyBlue text-xl'/>
                            <p  className='font-bold'>Expert Multispeciality Doctors With Us</p>
                        </div>
                        <div className='flex items-center gap-3 '>
                            <FaRegCircleCheck  className='text-nevyBlue text-xl'/>
                            <p  className='font-bold'>24 / 7* Services</p>
                        </div>
                        <div className='flex items-center gap-3 '>
                            <FaRegCircleCheck  className='text-nevyBlue text-xl'/>
                            <p  className='font-bold'>In Affordable Prices</p>
                        </div>
                        <div >
                           
                            <button className=' bg-nevyBlue  text-white p-2 md:w-1/5  w-full rounded-full flex items-center justify-center cursor-pointer'>Read More</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
  )
}

export default Aboutus