import React from 'react'
import Home from './Home';
import Section2 from './Section2'
import Section3 from './Section3'
import Location from './Location';
import Contact from './Contact';

const Aboutus = () => {
  return (
    <div>
      <Home/>
      <Section2/>
      <Section3/>
      <Location/>
      <Contact/>
    </div>
  )
}

export default Aboutus
