import React from 'react'
import blog1 from "../../img/neurology/blog1.png"
import blog2 from "../../img/neurology/blog2.png"
import blog3 from "../../img/neurology/blog3.png"


function Section4() {
  return (
    <>
    <section className='bg-opacBlue py-10'>

        <div className='flex flex-col gap-6'>
          <div className="pl-10 flex flex-col items-center gap-6">
            <h2 className='flex text-4xl text-center   '><span className='text-naveyBlue font-bold'>Our  </span> &nbsp; <span className='text-yellow font-bold'> Blogs</span></h2>
            <p className='text-start '>To operate as a world-class heart hospital, we incorporate the latest technological<br/> advances and ethical practices toprovide quality heart care at a reasonable cost.</p>
          </div>
          <div className="flex gap-5 flex-wrap  items-center justify-center ">
            <div className="rounded-xl gap-2 w-96  flex flex-col  justify-center text-start md:p-0 p-4">
              <img src={blog1} className='' alt="" />
              <h2 className='font-bold text-3xl'>Nurology</h2>
              <p className=' '>Cutting-edge breakthrough in neurology research reveals promising findings in the treatment of Alzheimer's disease, potentially offering new hope for patients and families grappling with cognitive decline.</p>
              <button className='border p-2 rounded border-nevyBlue text-nevyBlue bg-white w-2/5'>Read More</button>
            </div>
           
          <div className="rounded-xl gap-2 w-96  flex flex-col items-center justify-center text-start md:p-0 p-4 md:mt-5 mt-0">
              <img src={blog2} className='' alt="" />
              <h2 className='font-bold text-3xl'>Vascular Surgery</h2>
              <p className=''>Pioneering techniques in vascular surgery reduce recovery times and enhance patient outcomes, marking a significant advancement in the field's ability to address complex circulatory conditions with precision and effectiveness.</p>
              <button className='border p-2 rounded border-nevyBlue text-nevyBlue bg-white w-2/5'>Read More</button>
            </div>
          <div className="rounded-xl gap-2 w-96 flex flex-col items-center justify-center text-start md:p-0 p-4">
        <img src={blog3} className='' alt="" />
        <h2 className='font-bold text-3xl'>Gynaecology</h2>
        <p className=' '>Innovative fertility treatment methods in gynecology provide renewed optimism for couples struggling with infertility, offering tailored solutions and personalized care to fulfill their dream of parenthood.</p>
        <button className='border p-2 rounded border-nevyBlue text-nevyBlue bg-white w-2/5'>Read More</button>
      </div>
    </div>

    </div>
    </section>
    </>
  )
}

export default Section4