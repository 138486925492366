import React from 'react'
import  { useState } from 'react';


function Section2() {
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        number: '',
        subject: '',
        pdf: null,
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, pdf: file });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
    };
    
  return (
    <>
       

        <section className=' container mx-auto'>
            <h1 className='font-bold text-3xl text-nevyBlue py-10'>Contact Us <br/>For your Appointment </h1>
            <p className='align-center md:p-0 p-3'>To schedule an appointment with one of our healthcare professionals, please fill out the appointment request form on our website or give us a call at [insert phone number]. Our friendly staff will be happy to assist you in booking a convenient time for your visit.</p>
            <h1 className='text-3xl md:text-left text-nevyBlue'>Get In Touch</h1>


            <div className=" mx-auto ">
            <form onSubmit={handleSubmit}>
                <input type='text'></input>

            <div className="w-full mx-auto p-6  flex flex-wrap">
                    <div className="w-full md:w-1/2 px-4 mb-4">
                        {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label> */}
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="mt-1 block w-full h-8  border  border-#ACACAC rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter Name :"  style={{ fontSize: '15px',color:'#122D51',padding:'20px' }}/>
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                        
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="t-1 block w-full h-8 border  border-#ACACAC rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="E-mail  :"  style={{ fontSize: '15px',color:'#122D51',padding:'20px' }} />
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                        
                        <input type="text" id="number" name="number" value={formData.number} onChange={handleChange} className="t-1 block w-full h-8 border  border-#ACACAC rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="NumberPhone Number :"  style={{ fontSize: '15px',color:'#122D51',padding:'20px' }} />
                    </div>
                    <div className="w-full md:w-1/2 px-4 mb-4">
                        
                        <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} className="t-1 block w-full h-8 border  border-#ACACAC rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Subject :"  style={{ fontSize: '15px',color:'#122D51',padding:'20px' }} />
                    </div>
            </div>
                


<div className="mb-4 pb-5 w-5/5 md:w-1213 border border-black rounded" style={{ margin: '0px 35px', textAlign: 'center' }}>
  <label htmlFor="pdf" className="block text-sm font-medium text-gray-500  font-bold text-left p-4">Upload your previous Recipes</label>
  <label htmlFor="pdf" className="block text-sm font-medium text-gray-500 font-bold ">Files Supported: PDF, TEXT, DOC, DOCX</label>
  <label htmlFor="pdf" className="block w-4/5 md:w-40 text-white py-3 text-sm font-medium text-gray-700 bg-yellow rounded-md px-4 py-2 cursor-pointer inline-block mt-4">
    Choose File
    <input
      type="file"
      id="pdf"
      name="pdf"
      onChange={handleFileChange}
      className="hidden py-3"
    />
  </label>
</div>



                <div className="mb-4 " style={{margin: '25px 34px'}}>
                    
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="t-1 block w-full  border h-18  border-#ACACAC rounded shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder='Write your message :'  style={{ fontSize: '15px',color:'#122D51',padding:'15px' }} ></textarea>
                </div>
                
                <button type="submit" className="md:w-1/5 w-4/5 bg-yellow hover:bg-light text-white font-bold py-2 px-4 align-start rounded focus:outline-none focus:shadow-outline align-left p-5">Submit Now</button>
            </form>
        </div> 
        </section>





        
    </>
  )
}



export default Section2