import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Footer from './components/footer';
import Navbar from './components/navbar';
import Aboutus from './Pages/Aboutus/Aboutus';
import Specialties from './Pages/Specialties/Specialties';
import Contactus from './Pages/Contactus/Contactus'
import Neurology from './Pages/Neurology/Neurology';
import Landingpage from './Pages/Landingpage/Landingpage';



function App() {
  const location = useLocation();
  const hideNavbarOnLanding = location.pathname === '/';
  return (
    <div className="App">
     {!hideNavbarOnLanding && <Navbar />}

  <Routes>
    <Route path='/' element={<Landingpage/>}/>
    <Route path="/home" element={<Home/>} />
    <Route path="/aboutus" element={<Aboutus/>} />
    <Route path="/specialties" element={<Specialties />}>
            

    </Route>
    <Route path="specialties" element={<Specialties />} />
    <Route path="neurology" element={<Neurology />} />
    <Route path='/contactus' element={<Contactus/>}/>

  
  </Routes>

  {!hideNavbarOnLanding &&  <Footer/>}

 
    </div>
    
  );
}

export default App;
