import React from 'react'

function Section1() {
  return (
    <>
          <section className=" bg-light pt-4  h-96  " >
            <div className='md:text-left mx container mx-auto flex flex-col gap-8'>
            <h1 className='font-bold text-2xl  text-white pt-20'>Consulating / Neurology</h1>
            <h1 className='font-bold md:text-6xl text-3xl text-white '>Neurology</h1>

            </div>
    </section>
    </>
  )
}

export default Section1