import React from 'react'
import banner from "../../img/specialties/banner.png"
import { CiLocationOn } from "react-icons/ci";
import { VscNotebook } from "react-icons/vsc";

function Section1() {
  return (
    <>
        <div className='bg-light pt-4 ' >
        <div className='flex flex-col gap-0'>
        
          <div className="flex flex-col-reverse  md:flex-row items-center justify-around gap-y-10 md:gap-10">
            <div  className="flex flex-col items-center md:items-start justify-start h-full px-1 md:px-0  gap-3 text-center md:text-start ">
                
                <h1 className='flex md:text-4xl text-2xl items-center font-bold md:text-left text-white'>Clinical Excellence Services,<br />Enhancing Patient Care  </h1>
            
              <div className="flex flex-col md:flex-row  justify-between gap-5 md:gap-5 py-8">
                <div className='flex'>

                  <button className=' flex item-center justfy-center  gap-2 bg-white p-2 rounded-xl text-nevyBlue  w-55'><span className='pt-1'><CiLocationOn /></span>Choose hospital</button> 
                </div>
                <div className='flex'>
                  
                  <button className=' flex item-center justify-center gap-2 bg-white p-2 rounded-xl text-nevyBlue w-55'><span className='pt-1'><VscNotebook /></span>Book an appointment</button>       
                </div>
              </div>
            </div>
            <img src={banner}  alt="" className=' h-200 w-250 md:h-[450px] md:w-[450px] object-cover md:object-cover ' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Section1