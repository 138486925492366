import React, {useState,useEffect} from 'react'
import banner from "../../img/home/fbanner.png"
import { CiLocationOn } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscNotebook } from "react-icons/vsc";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';

const Banner = () => {

  const [selectedBranch, setSelectedBranch] = useState('');
  const [isOpen, setIsOpen] = useState(false);



  
  const updateBranch =(value)=>{
    localStorage.setItem("branch",value)
}

useEffect(() => {
   
  const storedBranch = localStorage.getItem('branch');
  setSelectedBranch(storedBranch);
}, [updateBranch]);

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};


  return (
    <div className='bg-light pt-4 ' >
     <div className='flex flex-col gap-0'>
        {/* <Navbar/> */}
        <div className="flex flex-col-reverse  md:flex-row items-center justify-center gap-0 md:gap-10">
        <div  className="flex flex-col items-center md:items-start justify-start h-full px-1 md:px-0 md:pt-20 gap-3 text-center md:text-start ">
           <h2 className='text-4xl font-bold text-yellow'>500/-</h2>
            <h2 className='flex text-4xl font-bold text-white'>Wellness Plan, Access All Treatments for a Year <br />  Your Pathway to Affordable Health!</h2>
            {/* <p className=''>Excellent Health Service For all patients who come to our hospital</p> */}
            <div className="flex flex-col md:flex-row  justify-between gap-5 md:gap-20">
        <button className='bg-yellow p-2 rounded text-white'>Emergency help</button>       
            </div>
            </div>
        <img src={banner}  alt="" className=' h-200 w-250 md:h-[450px] md:w-[450px] object-cover md:object-cover ' />
        </div>


<div className="flex flex-col items-center mt-5 md:mt-0 ">
  <div className="flex relative text-nevyBlue text-xl font-bold flex-col md:flex-row py-5 md:py-0 items-center justify-between h-auto md:h-12 bg-white w-full md:w-2/3 rounded px-5 mb-10">
   
    <div className="flex ">
      <h4  onClick={toggleDropdown} className='flex items-center justify-center cursor-pointer '><CiLocationOn /> {selectedBranch ? `${selectedBranch}` : 'Select Branch'} <RiArrowDropDownLine /></h4>
   
   <div  className={`absolute top-14 left-0 transition-all ${isOpen ? 'block' : 'hidden'}`}>
   <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
   <ul className=' text-left shadow-md text-nevyBlue list-none bg-white w-56 p-4 rounded-lg flex flex-col gap-1'>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("vijay nagar")}} >Vijay Nagar</li>
            </Link>
            <Link to='/home'>

                <li onClick={()=>{updateBranch("tolichowki")}}>tolichowki</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("chanchalguda")}}>chanchalguda</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Mahdi Colony")}}>Mahdi Colony</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("mehdipatnam")}}>mehdipatnam</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Kishan bagh")}}>Kishan bagh</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Dar shifa Charminar")}}>Dar shifa Charminar</li>
            </Link>


        </ul>
        </Transition>
   </div>


    </div>
    <div className="">
      <h4 className='flex items-center justify-center'><VscNotebook /> Book an Appointment</h4>
    </div>
    <div className="">
      <h4 className='flex items-center justify-center'><IoSearchOutline /> Search here..</h4>
    </div>

  </div>
</div>

     </div>

     
    </div>
  )
}

export default Banner
