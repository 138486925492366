import React from 'react'

function Section3() {
  return (
    <>
    <section className='mt-10 container mx-auto'>
        <h1 className='md:text-3xl text-2xl py-5'>OTHER SPECIALITIES</h1>
        <div className="flex flex-col md:flex-row flex-wrap md:ml-20 ml-0">
            <div className="flex-1 md:px-12 px-0 md:pl-0 pl-10 py-8">
                <ul className='list-disc text-left  text-nevyBlue text-left  text-nevyBlue'>
                    <li>Neonatology</li>
                    <li>Vascular Surgery</li>
                    <li>Psychiatry</li>
                    <li>Dentistry</li>
                    <li>ENT (Ear, Nose, Throat)</li>
                </ul>
            </div>
            <div className="flex-1 md:px-12 px-0 md:pl-0 pl-10 py-8">
                <ul className='list-disc text-left  text-nevyBlue'>
                    <li>Pulmonology</li>
                    <li>Rheumatology</li>
                    <li>Neurosurgery</li>
                    <li>Radiology</li>
                    <li>Plastic surgery</li>
                </ul>
            </div>
            <div className="flex-1 md:px-12 px-0 md:pl-0 pl-10 py-8">
                <ul className='list-disc text-left  text-nevyBlue'>
                    <li>Dermatology</li>
                    <li>Ophthalmology</li>
                    <li>Pediatrics</li>
                    <li>Endocrinology</li>
                    <li>ENT Urology</li>
                </ul>
            </div>
            <div className="flex-1 md:px-12 px-0 md:pl-0 pl-10 py-8">
                <ul className='list-disc text-left  text-nevyBlue'>
                    <li>Cardiology</li>
                    <li>Neurology</li>
                    <li>Gastroenterology</li>
                    <li>Nephrology</li>
                </ul>
            </div>
            <div className="flex-1 md:px-12 px-0 md:pl-0 pl-10 py-8">
                <ul className='list-disc text-left  text-nevyBlue'>
                    <li>Orthopaedic</li>
                    <li>Oncology</li>
                    <li>Gynecology</li>
                </ul>
            </div>
        </div>


       

    </section>
    </>
  )
}

export default Section3