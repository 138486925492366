import React, { useEffect, useState } from 'react'
import Banner from './Banner'
// import Consulation from './Consulation/Consulation'
// import Exceptional from './exceptional'
// import Chooseus from './Chooseus'
// import Services from './Services'
import BannerTwo from './Bannertwo'
import BannerThree from './BannerThree';
import BannerFour from './BannerFour';
import Aboutus from "./Aboutus"
import Consultant from './Consultant'
import Departments from "./Departments"
import Articles from "./Articles"
import Services from './Services';
import Excellence from './Excellence';
import Testimonials from './Testimonials';
import Herosection from './Herosection';

const Home = () => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const banners = [<Banner />, <BannerTwo />, <BannerThree />, <BannerFour />];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 2000); 

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='flex flex-col gap-12 '>
       {/* {banners[currentBannerIndex]} */}
       {/* <BannerFour/> */}
       <Herosection/>
       <Aboutus/>
       <Services/>
       <Consultant/>
       <Excellence/>
       <Departments/>
       <Articles/>
       <Testimonials/>
      {/* <Chooseus/>
      <Services/>
      <Exceptional/>
      <Consulation/> */}
    </div>
  )
}

export default Home