import React,{ useState,useEffect } from 'react'
import { GiHamburgerMenu } from "react-icons/gi";
import { FaCaretDown } from "react-icons/fa";
import logo from "../img/landingpage/logo.png"
import { MdOutlineLocalPhone } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { CiFacebook } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { CiYoutube } from "react-icons/ci";
const Navbar = () => {
 

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSpecialtiesOpen, setSpecialtiesOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
  <div className={`bg-white text-white w-full `}>
    <div className="md:sticky static top-0 bg-nevyBlue py-2">
        <div className="h-30 md:h-10 flex flex-col md:flex-row items-center justify-between px-5 py-s md:px-10 gap-2 md:gap-0 text-white">
          <div className="flex flex-wrap justify-center gap-2 md:gap-6 ">
          <CiFacebook className='text-2xl cursor-pointer'/>
          <FaWhatsapp className='text-2xl cursor-pointer'/>
          <FaInstagram className='text-2xl cursor-pointer'/>
          <FaXTwitter className='text-2xl cursor-pointer'/>
          <CiLinkedin className='text-2xl cursor-pointer'/>
          <CiYoutube className='text-2xl cursor-pointer'/>
            {/* <div className="flex  items-center justify-center gap-1">
              <MdOutlineLocalPhone />
              
            </div>
            <div className="flex items-center  justify-center gap-1">
              <GoMail />
              <a href="mailto:Contact@sheikhafathimahospital.com"><p className='email-footer text-xl'>Contact@sheikhafathimahospital.com</p></a>
            </div> */}

          </div>

          <div className="">
            <div className="flex gap-3  items-center justify-center  ">
             <p>Emergency 24 x 7</p>
             <p className='text-xl border rounded-3xl px-2 py-1'>  <a href="tel:+91-9392691924">+91-9392691924</a></p>
         
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-1 py-2">
                <img src={logo} alt='' className='md:h-16 h-14'/>
                <h4 className='font-bold text-sm md:text-xl  text-nevyBlue'>SHEIKHA FATHIMA ZAHARA HOSPITALS</h4>
                </div>

<nav className="p-4 md:relative static bg-nevyBlue">
  <div className="max-w-7xl mx-auto flex items-center justify-center  ">
    
    
    <ul className={`md:flex flex-wrap md:gap-5 ${isMenuOpen ? 'block' : 'hidden'}`}>
      <Link to='/home'><li className='p-3 md:p-0 ' onClick={() => setIsMenuOpen(false)}>Home</li></Link>
      <Link to='/aboutus'><li className='p-3 md:p-0' onClick={() => setIsMenuOpen(false)}>About Us</li></Link>
      <li className='p-3 md:p-0 z-10'
        onMouseEnter={() => setSpecialtiesOpen(true)}
        onMouseLeave={() => setSpecialtiesOpen(false)}
      >
        <span className="hover:bg-yellow-500  rounded-md p-3 md:p-3">Specialties</span>
        {isSpecialtiesOpen && (
        
          <ul className='absolute bg-nevyBlue w-36 mt-3 z-10'>
  <Link to='/specialties' onClick={() => setIsMenuOpen(false)}>
    <li className="px-3 py-2 hover:bg-gradient-to-r from-nevyBlue to-yellow-500 text-white hover:text-white transition-colors duration-300">Specialties</li>
  </Link>
  <Link to='/neurology' onClick={() => setIsMenuOpen(false)}>
    <li className="px-3 py-2 hover:bg-gradient-to-r from-nevyBlue to-yellow-500 text-white hover:text-white transition-colors duration-300">Neurology</li>
  </Link>
</ul>

        )}
      </li>
      <li className='p-3 md:p-0'>Panel Of Doctors</li>
      <li className='p-3 md:p-0'>Patient Guide</li>
      <li className='p-3 md:p-0'>Blog</li>
      <Link to='/contactUs' onClick={() => setIsMenuOpen(false)}><li>Contact Us</li></Link>
    </ul>

   
    <div className="md:hidden">
      <button className="text-black" onClick={toggleMenu}>
        {isMenuOpen ? (
          <AiOutlineClose className="h-6 w-6 fill-current absolute top-32 right-0" />
        ) : (
          <GiHamburgerMenu className="h-6 w-6 fill-current" />
        )}
      </button>
    </div>
  </div>
</nav>
    </div>  
  )
}

export default Navbar
