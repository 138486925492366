import React from 'react'
// import { GoMail } from "react-icons/go";

function Section3() {
  return (
    <>


<section className='pt-6 container mx-auto md:container md:mx-auto md:p-0 p-5'>

  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-1 mt-10 justify-center'>
    <div className='bg-white rounded-lg shadow-xl overflow-hidden bg-nevyBlue md:w-4/5 text-white md:p-5 p-2 '>
      {/* <h1 className=''><GoMail/></h1> */}
      <h4 className='p-5 text-white font-bold text-xl '>E-mail</h4>
      <h6>  <a href="tel:+91-9392691924">+91-9392691924</a></h6>
      <h6 className='md:text-lg text-xs'> <a href="mailto:Contact@sheikhafathimahospital.com">Contact@sheikhafathimahospital.com</a></h6>
    </div>
    <div className='bg-white rounded-lg shadow-xl overflow-hidden bg-nevyBlue md:w-4/5 text-white md:p-5 p-2 '>
      
      <h4 className='p-5 text-white  font-bold text-xl '>Address</h4>
      <h6 className='md:text-lg text-xs'>10-2-300, Nukkula Arcade, a/2, CROSS ROADS, opp. ITI, Syed Azam Colony, Mallepally, Hyderabad, Telangana 500001</h6>
    </div>
    <div className=' rounded-lg shadow-xl overflow-hidden bg-nevyBlue md:w-4/5 text-white md:p-5 p-2 '>
      
      <h4 className='p-5 text-white  font-bold text-xl'>Hospital Timings</h4>
      <h6>Mon-sat 9:00 am - 10 pm</h6>
      <h6>Sunday 10:00 am - 5:00pm </h6>
    </div>
  </div>
</section>

    </>
  )
}

export default Section3