import React,{useState} from 'react'
import services1 from '../../img/home/services1.png'
import services2 from '../../img/home/services2.png'
import services3 from '../../img/home/services3.png'
import services4 from '../../img/home/services4.png'
import services5 from '../../img/home/services5.png'
import lightblue1 from '../../img/home/lightblue1.png'
import lightblue2 from '../../img/home/lightblue2.png'
import lightblue3 from '../../img/home/lightblue3.png'
import lightblue4 from '../../img/home/lightblue4.png'
import lightblue5 from '../../img/home/lightblue5.png'

function Services() {
    const [hovered1, setHovered1] = useState(false);
    const [hovered2, setHovered2] = useState(false);
    const [hovered3, setHovered3] = useState(false);
    const [hovered4, setHovered4] = useState(false);
    const [hovered5, setHovered5] = useState(false);
  return (
    <>
        <div className='flex container mx-auto md:gap-3 gap-8 p-5 md:flex-row flex-col'>



        <div>
            <div
                onMouseEnter={() => setHovered1(true)}
                onMouseLeave={() => setHovered1(false)}
                className="relative"
            >
                <img src={services5} alt="" className="w-full h-auto" />
                {hovered1 && (
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center transition-transform transform-gpu hover:scale-105 duration-500">
                        <img src={lightblue1} alt="" className="w-full h-auto" />
                        <div className='bg-nevyBlue text-white rounded-b-xl p-3 w-full text-start '>
                            <p className='font-bold'>Instant Video Consultation</p>
                            <p>Connect within 60 secs</p>
                        </div>
                    </div>
                )}
            </div>
        </div>

        
    

        <div>
            <div
                onMouseEnter={() => setHovered2(true)}
                onMouseLeave={() => setHovered2(false)}
                className="relative"
            >
                <img src={services4} alt="" className="w-full h-auto"/>

            {hovered2 &&(
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center transition-transform transform-gpu hover:scale-105 duration-500">
                <img src={lightblue2} alt="" className="w-full h-auto"  />
                <div  className='bg-nevyBlue text-white rounded-b-xl p-3 text-start w-full'>
                    <p className='font-bold'>Find Doctors Near<br/> You</p>
                    <p>Confirmed appointments</p>
                </div>
            </div>
            )}
                
            </div>
        </div>

        <div>
            <div
                onMouseEnter={() => setHovered3(true)}
                onMouseLeave={() => setHovered3(false)}
                className="relative"
            >
                <img src={services1} alt="" className="w-full h-auto"/>

            {hovered3 &&(
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center transition-transform transform-gpu hover:scale-105 duration-500">
                <img src={lightblue3} alt="" className="w-full h-auto"  />
                <div  className='bg-nevyBlue text-white rounded-b-xl p-3 text-start w-full'>
                    <p className='font-bold'>Medicines</p>
                    <p>Essentials at your doorstep</p>
                </div>
            </div>
            )}
                
            </div>
        </div>

        <div>
            <div
                onMouseEnter={() => setHovered4(true)}
                onMouseLeave={() => setHovered4(false)}
                className="relative"
            >
                <img src={services3} alt="" className="w-full h-auto"/>

            {hovered4 &&(
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center transition-transform transform-gpu hover:scale-105 duration-500 ">
                <img src={lightblue4} alt="" className="w-full h-auto"  />
                <div  className='bg-nevyBlue text-white rounded-b-xl p-3 text-start w-full'>
                    <p className='font-bold'>Lab Tests</p>
                    <p>Sample pickup at your home</p>
                </div>
            </div>
            )}
                
            </div>
        </div>

        <div>
            <div
                onMouseEnter={() => setHovered5(true)}
                onMouseLeave={() => setHovered5(false)}
                className="relative"
            >
                <img src={services2} alt="" className="w-full h-auto"/>

            {hovered5 &&(
                <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center transition-transform transform-gpu hover:scale-105 duration-500">
                <img src={lightblue5} alt="" className="w-full h-auto"  />
                <div  className='bg-nevyBlue text-white rounded-b-xl p-3 text-start w-full'>
                    <p className='font-bold'>Surgeries</p>
                    <p>Safe and trusted surgery centers</p>
                </div>
            </div>
            )}
                
            </div>
        </div>
            


        </div>
    </>
  )
}

export default Services