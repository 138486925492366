import React from 'react'
import Acne from '../../img/home/Acne.png'
import child from '../../img/home/child.png'
import coughing from '../../img/home/coughing.png'
import Group from '../../img/home/Group.png'
import period from '../../img/home/period.png'
import wellness from '../../img/home/wellness.png'

function Consultant() {
  return (
    <>
            <div className='flex flex-col md:flex-row container mx-auto  p-5 '>
                <div className='flex flex-col gap-5 justify-center text-start  md:px-0 px-3 '>
                    <p className='md:text-3xl text-2xl font-bold'>Consult top doctors online for any health concern</p>
                    <p>
                        Private online consultations with verified doctors in all specialists
                    </p>
                    <button className='bg-nevyBlue  text-white p-2 md:w-2/5 w-4/5 rounded-full flex items-center justify-center cursor-pointer'>Download App</button>
                </div>
                <div className='grid md:grid-cols-3  grid-cols-1 items-center gap-5 md:px-0 px-5 md:mt-0 mt-3'>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={period} alt="" />
                        <p className='font-bold'>Period doubts or Pregnancy</p>
                        <button className='ease-in duration-150 hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={Group} alt="" />
                        <p className='font-bold'>Performance issues<br/> in bed</p>
                        <button className='ease-in duration-150 hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={child} alt="" />
                        <p className='font-bold'>Child not feeling<br/> well</p>
                        <button className='ease-in duration-150 hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={Acne} alt="" />
                        <p className='font-bold'>Acne, pimple or<br/> skin issues</p>
                        <button className='ease-in duration-150 hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={coughing} alt="" />
                        <p className='font-bold'>Cold, cough or<br/> fever</p>
                        <button className='ease-in duration-150  hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>
                    <div className='shadow-lg items-center p-3 flex flex-col gap-3 rounded-xl'>
                        <img src={wellness} alt="" />
                        <p className='font-bold'>Depression or<br/> anxiety</p>
                        <button className='ease-in duration-150  hover:bg-nevyBlue  hover:text-white hover:rounded-xl text-nevyBlue font-bold p-2'>CONSULT NOW</button>
                    </div>

                </div>
            </div>
    </>
  )
}

export default Consultant