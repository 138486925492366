import React,{useState,useEffect} from 'react'
import banner from "../../img/neurology/banner.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';




function Section2() {


const [currentSlide, setCurrentSlide] = useState(0);

  const CustomNextArrow = ({ className, onClick }) => (
    <button
      className={`${className} bg-green text-white p-2 rounded`}
      style={{ right: '10px' }}
      onClick={onClick}
    >
      Next
    </button>
  );

  const CustomPrevArrow = ({ className, onClick }) => (
    <button
      className={`${className} bg-green text-white p-2 rounded`}
      style={{ left: '10px' }}
      onClick={onClick}
    >
      Prev
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (index) => setCurrentSlide(index),
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

 

  const [showAppointmentPopup, setShowAppointmentPopup] = useState(false);
  const [showBioPopup, setShowBioPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('bio');

  const switchTab = (tab) => setActiveTab(tab);

  const toggleAppointmentPopup = () => {
    setShowAppointmentPopup(!showAppointmentPopup);
    if (!showAppointmentPopup) { 
      setActiveTab('book'); 
    }
  };
  
  const toggleBioPopup = () => {
    setShowBioPopup(!showBioPopup);
    if (!showBioPopup) { 
      setActiveTab('bio'); 
    }
  };
 
 
//  --------dates-----
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState('');

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const getDaysInMonth = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const days = [];

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; 
  };


  // ------timing slot-------

  
  const times = ['9:00 AM', '9:10 AM', '9:20 AM', '9:30 PM', '9:40 PM', '9:50 PM', '10:00 PM', '10:10 PM'];
  const [selected, setSelected] = useState(null);

  const handleSlotSelect = (time) => {
    setSelected(time);
  };

  return (
    <>
    <section className='container mx-auto'>

        <div className='flex flex-col md:flex-row items-center  gap-10 mt-5 '>



<Slider {...settings} className='scroll w-full lg:w-2/5'>
    <div className="flex flex-col items-center md:items-start justify-center md:pl-20 pl-0 md:text-start w-full md:w-1/2 pb-10 mt-20 ml-0 md:ml-12">
        <img src={banner} alt="" className="w-full md:w-3/5" />
        <div className="flex flex-col items-start gap-6 w-full ">
            <h1 className="text-lg md:text-2xl font-bold  ml-0 md:ml-5">Dr. Pradyot Kumar Rath</h1>
            <h1 className="text-lg font-bold text-gray-600 md:ml-10 ml-0">Consulting / Neurology</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-5 md:gap-20 ml-5 md:ml-10">
                <button className="bg-yellow p-2 rounded text-white" onClick={toggleAppointmentPopup}>Book Appointment</button>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-20 ml-5 md:ml-10">
                <button className="bg-white w-36 rounded border border-yellow-500 text-yellow p-2" onClick={toggleBioPopup}>Read Bio</button>
            </div>
        </div>
    </div>

    <div className="flex flex-col items-center md:items-start justify-center md:pl-20 pl-0 md:text-start w-full md:w-1/2 pb-10 mt-20 ml-0 md:ml-12">
        <img src={banner} alt="" className="w-full md:w-3/5" />
        <div className="flex flex-col items-start gap-6 w-full ">
            <h1 className="text-lg md:text-2xl font-bold  ml-0 md:ml-5">Dr. Pradyot Kumar Rath</h1>
            <h1 className="text-lg font-bold text-gray-600 md:ml-10 ml-0">Consulting / Neurology</h1>
            <div className="flex flex-col md:flex-row justify-center items-center gap-5 md:gap-20 ml-5 md:ml-10">
                <button className="bg-yellow p-2 rounded text-white" onClick={toggleAppointmentPopup}>Book Appointment</button>
            </div>
            <div className="flex flex-col md:flex-row justify-between gap-20 ml-5 md:ml-10">
                <button className="bg-white w-36 rounded border border-yellow-500 text-yellow p-2" onClick={toggleBioPopup}>Read Bio</button>
            </div>
        </div>
    </div>
</Slider>








        
        


 {showAppointmentPopup && (
 <div className="fixed  z-20 inset-0 bg-black bg-opacity-50 flex md:justify-center justify-start items-center border border-#E1E1E1">
   
 <div className="bg-white p-4 md:w-4/5 w-3/5 sm:w-4/5 relative popup-container overflow-y-auto h-[400px] md:h-auto md:overflow-y-hidden">

  
   <button
     className="absolute top-0 right-0 font-bold bg-transparent border-none text-gray-500 text-xl cursor-pointer pr-5"
     onClick={toggleAppointmentPopup}
   >
     &#10006; 
   </button>
   <div className='border-2 border-#E1E1E1 md:m-5 m-2 rounded-lg'>
     
       <div className="flex  justify-around mb-4 items-center">
         <button className={`border-b-4 font-bold ${activeTab === 'book' ? 'border-nevyBlue' : 'border-transparent'}`} 
           onClick={() => switchTab('book')}>
           Book Digital Consult
         </button>
         <button className={`border-b-4 font-bold ${activeTab === 'visit' ? 'border-nevyBlue' : 'border-transparent'}`} 
           onClick={() => switchTab('visit')}>
             Book Hospital Visit
         </button>
       
       </div>
       <hr/>
       <div className='px-5 '>
         {activeTab === 'book' && (
           <>
           <div  className='text-left flex flex-col gap-3 p-2'>

           <p>
               Book Digital Consult
           </p>
          
           </div>

           </>
         )}
         



{activeTab === 'visit' && (
  <>
    <p className=' font-bold text-3xl text-left'>Book Hospital Visit</p>
   



<div className="flex flex-col items-center">
      <div className="flex flex-wrap justify-between w-full md:mb-1 mb-3 font-bold text-green">
        <button onClick={prevMonth}>Previous Month</button>
        <h1 className="text-2xl font-bold text-nevyBlue">{currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h1>
        <button onClick={nextMonth}>Next Month</button>
      </div>
      <div className=" pt-3 max-w-screen-lg">
        <div className="flex flex-wrap gap-2">
          {getDaysInMonth().map((date, index) => (
            <button
              key={index}
              onClick={() => handleDateSelect(date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric' }))}
              className={`p-2 rounded-xl border border-gray-300 font-bold ${
                selectedDate === date.toLocaleDateString('en-US', { weekday: 'long', day: 'numeric' }) ? 'bg-nevyBlue text-white' : isWeekend(date) ? ' text-black' : 'bg-white text-gray-900'
              }`}
            >
              <div className={isWeekend(date) ? 'text-red-500' : 'text-black'}>{date.toLocaleDateString('en-US', { weekday: 'short' })}</div>
              <div>{date.toLocaleDateString('en-US', { day: 'numeric' })}</div>
            </button>
          ))}
        </div>
      </div>
      {selectedDate && (
        <p className="font-bold">You have selected: {selectedDate}</p>
      )}
    </div>


      <h1 className="md:text-3xl text-xl md:mt-0 mt-5 text-left font-bold mb-2">MORINING SLOTS</h1>
    <div className="flex flex-col flex-wrap gap-2 items-center max-w-screen-lg">
      <div className=" gap-2  p-4">
        {times.map((time) => (
          <button
            key={time}
            className={`w-24 font-bold p-2 md:m-2 mb-2 text-lg rounded-xl border border-gray-300 ${selected === time ? 'bg-nevyBlue text-white' : 'bg-white text-black'}`}
            onClick={() => handleSlotSelect(time)}
          >
            <span className="text-sm">{time}</span>
          </button>
        ))}
      </div>
      {selected && (
        <p className="font-bold pt-1">You have selected: {selected}</p>
      )}
    </div>


    <button className="bg-yellow p-2 rounded text-white md:w-1/5 w-full " >Continue Booking</button>
    <p className='text-nevyBlue font-bold text-lg m:m-0 m-4 '>No Booking Fees</p>

    
  </>
)}




       </div>
   </div>
   
 </div>
</div>
    
)}



{showBioPopup && (
  
  <div className="fixed z-20 inset-0 bg-black bg-opacity-50 flex md:justify-center justify-start items-center border border-#E1E1E1">
   
    <div className="bg-white p-4 md:w-4/5 w-3/5 sm:w-4/5   relative popup-container overflow-y-auto h-[400px] md:h-auto md:overflow-y-hidden">

     
      <button
        className="absolute font-bold top-0 right-0 bg-transparent border-none text-gray-500 text-xl cursor-pointer pr-5"
        onClick={toggleBioPopup}
      >
        &#10006; 
      </button>
      <div className='border-2 border-#E1E1E1 md:m-5 m-2 rounded-lg'>
        
          <div className="flex  justify-around mb-4 items-center">
            <button className={`border-b-4 font-bold ${activeTab === 'bio' ? 'border-nevyBlue' : 'border-transparent'}`} 
              onClick={() => switchTab('bio')}>
              Bio
            </button>
            <button className={`border-b-4 font-bold ${activeTab === 'reviews' ? 'border-nevyBlue' : 'border-transparent'}`} 
              onClick={() => switchTab('reviews')}>
                Reviews
            </button>
          
          </div>
          <hr/>
          <div className='px-5 bio'>
            {activeTab === 'bio' && (
              <>
              <div  className='text-left flex flex-col md:gap-3 gap-8 p-2'>

              <p>
                Dr. Santosh Kumar Dora, MD, DM, is an esteemed and experienced doctor with over 19 years of experience, specialising in Cardiology. At the Asian Heart Institute, he is an Internal Medicine Consultant in Cardiology, Cardiac Electrophysiology, and Pacing. He completed his MD in General Medicine at SCB Medical College, Cuttack, Orissa. He furthered his specialisation by obtaining a DM in Cardiology from SCTIMST, Trivandrum, India.

              </p>
              <p>
                Throughout his career, Dr. Dora has actively participated in various research projects and clinical studies. Notably, during his fellowship at Cedars-Sinai Medical Center, he worked on multiple projects related to Electrophysiology and Pacing, which contributed to advancements in the field. He has also published several papers in reputable journals, showcasing his commitment to research and his contributions to the medical community.
              </p>
              </div>

              </>
            )}
            {activeTab === 'reviews' && (
              <>
               

                                      <p className='text-yellow font-bold text-2xl text-left '>Read Reviews</p>
              <div class="flex gap-4  text-left reviews ">
                          <div class="flex   flex-col  ">
                                    <div>
                                      <p className='font-bold text-lg'>KRISHNA PRASAD SINGH</p>
                                      <p className='font-bold'>Patient</p>
                                      <p className='text-nevyBlue'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
                                      <p className="text-left">Mr. Asif Khan and all the doctors and nurses miss Sahana at the 5th floor are exceptional, very prompt, humble, cooperative, energetic, well profound, and proficient in knowledge.</p>
                                    </div>

                                    <div>
                                      <p className='font-bold text-lg'>KRISHNA PRASAD SINGH</p>
                                      <p className='font-bold'>Patient</p>
                                      <p className='text-nevyBlue'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
                                      <p className="text-left">Mr. Asif Khan and all the doctors and nurses miss Sahana at the 5th floor are exceptional, very prompt, humble, cooperative, energetic, well profound, and proficient in knowledge.</p>
                                    </div>
                          </div>
                          <div class="flex  flex-col  ">
                                    <div>
                                      <p className='font-bold text-lg'>KRISHNA PRASAD SINGH</p>
                                      <p className='font-bold'>Patient</p>
                                      <p className='text-nevyBlue'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
                                      <p className="text-left">Mr. Asif Khan and all the doctors and nurses miss Sahana at the 5th floor are exceptional, very prompt, humble, cooperative, energetic, well profound, and proficient in knowledge.</p>
                                      </div>

                                      <div>
                                      <p className='font-bold text-lg'>KRISHNA PRASAD SINGH</p>
                                      <p className='font-bold'>Patient</p>
                                      <p className='text-nevyBlue'>&#9733;&#9733;&#9733;&#9733;&#9733;</p>
                                      <p className="text-left">Mr. Asif Khan and all the doctors and nurses miss Sahana at the 5th floor are exceptional, very prompt, humble, cooperative, energetic, well profound, and proficient in knowledge.</p>
                                    </div>
                          </div>
                          <div class="flex  text-left form1  ">
                                    
                                    <form className="flex flex-col bg-light  p-4 rounded  md:w-auto w-full" >
                                    <p className='text-white text-2xl font-bold'>Add Review</p>
                                      <p className='font-bold text-white'>Rate Your Experience With Us*</p>
                                      <p className='text-yellow'>&#9733;&#9733;<span className='text-black'>&#9733;&#9733;&#9733;</span></p>
                                      <p className='font-bold text-xl'>Your Profile*</p>
                                      {/* <div className='flex   md:gap-2 gap-6'>
                                        <input type="radio" id="patient" name="patientType" value="patient"  style={{ '--checked-color': 'text-green' }}/>
                                        <label htmlFor="patient">Patient</label>
                                        <input type="radio" id="relative" name="patientType" value="relative" />
                                        <label htmlFor="relative">Caregiver</label>
                                        <input type="radio" id="relative" name="patientType" value="relative" />
                                        <label htmlFor="relative">Relative</label>
                                      </div> */}
                                      <div className='flex md:gap-1 gap-6 flex-col md:flex-row'>
                                          <div className="flex items-center">
                                            <input type="radio" id="patient" name="patientType" value="patient"  />
                                            <label htmlFor="patient " >Patient</label>
                                          </div>
                                          <div className="flex items-center">
                                            <input type="radio" id="caregiver" name="patientType" value="caregiver" />
                                            <label htmlFor="caregiver">Caregiver</label>
                                          </div>
                                          <div className="flex items-center">
                                            <input type="radio" id="relative" name="patientType" value="relative" />
                                            <label htmlFor="relative">Relative</label>
                                          </div>
                                      </div>

                                      <div className='flex flex-col gap-1'>

                                      <label htmlFor="patientName">Your Name:</label>
                                      <input type="text" id="yourName" name="yourName" style={{ backgroundColor: 'white' ,paddingLeft:'10px'}}  placeholder='Name*'/>
                                      <label htmlFor="patientId">Patient ID:</label>
                                      <input type="text" id="patientId" name="patientId" style={{ backgroundColor: 'white',paddingLeft:'10px' }} placeholder='patient Id*'/>

                                      <label htmlFor="doctor">Select Your Doctor:</label>
                                      <select id="doctor" name="doctor" style={{ backgroundColor: 'white',paddingLeft:'10px' }}>
                                        <option value="Dr. John Doe">Dr. Santhosh Kumar</option>
                                        <option value="Dr. John Doe">Dr. Santhosh Kumar</option>
                                        <option value="Dr. John Doe">Dr. Santhosh Kumar</option>
                                      </select>

                                      <label htmlFor="message">Message:</label>
                                      <textarea id="message" name="message" style={{ backgroundColor: 'white',paddingLeft:'10px' }} placeholder='Write Your Experince Here'></textarea>
                                      <button type="submit" className="md:w-3/5 w-full bg-yellow hover:bg-light text-white font-bold py-2 px-4 align-start rounded focus:outline-none focus:shadow-outline align-left p-5">Submit Now</button>
                                      </div>

                                     
                                    </form>
                                  
                          </div>
</div>

              </>
            )}
          </div>
      </div>
      
    </div>
  </div>
)}  









                <div className=" w-full md:w-1/2 flex flex-col justify-center gap-6 md:p-0 p-5">
                    <h1 className='font-bold text-3xl text-left'>World-wide Recognition in Neurology</h1>
                    <p className='text-left'>Awarded one of the World’s Best Specialised Hospitals <br/>in Cardiology in 2022, 2023 and 2023 by Newsweek, <br/>Statista Inc., USA.</p>
                    <h1 className='font-bold text-3xl text-left'>World-wide Recognition in Neurology</h1>
                    <p className='text-left'>Awarded one of the World’s Best Specialised Hospitals in Cardiology in 2022, 2023 and 2023 by Newsweek, Statista Inc., USA.</p>
                </div>
        </div>
    </section>
    </>
  )
}

export default Section2