import React from 'react'
import imag from "../../img/landingpage/imag.png"


function Section1() {
  return (
    <>
       <div className='bg-light pt-4  wave' >
            <div className='flex justify-end'>
                <img src={imag} alt="" className='h-200 w-250 md:h-[450px] md:w-[450px] object-cover' />
            </div>


        </div>

    
    </>
  )
}

export default Section1