import React from 'react'

import Banner from './Section1';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3'
// import { Outlet } from 'react-router-dom';



const Specialties = () => {
  return (
    <>
       <div>
       <Section1/>
      <Section2/>
      <Section3/>
      
    </div>
    </>
  )
}

export default Specialties