import React,{useState} from 'react'


function Section3() {
    const faqs = [
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        { 
            question: 'Does online consultation available with all Allergist And Clinical Immunologists in hyderabad?',
            answer: '&amp;amp;lt;!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}--&amp;amp;gt; &nbsp;Yes, you can consult a Allergist And Clinical Immunologist online here at Apollo24|7. We have an excellent team of noted Allergist And Clinical Immunologists in hyderabad . A video consultation is enough for a Allergist And Clinical Immunologist help you out with your problem concerning your Manage allergies and treat immunity.'
        },
        
      
      ];



const [openIndex, setOpenIndex] = useState(null);

const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  
    
  return (
    <>


<div className="container mx-auto mt-10">
      {faqs.map((faq, index) => (
        <div key={index} className="mb-4 border-b">
          <button
            onClick={() => toggleFAQ(index)}
            className="flex justify-between items-center w-full py-2 px-4  text-nevyBlue hover:bg-yellow-600 hover:text-white hover:rounded-lg"
          >
            <span className="text-lg lg:text-left font-bold  items-center">{faq.question}</span>
            <span>{openIndex === index ? '\u25B2' : '\u25BC'}</span>
          </button>
          {openIndex === index && (
            <div className="mt-2 px-4  text-left pb-3">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
    </>
  )
}

export default Section3