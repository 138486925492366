import React from 'react'
import article1 from '../../img/home/article1.png'
import article2 from '../../img/home/article2.png'

function Articles() {
  return (
    <>
        <div className=' flex md:flex-row flex-col justify-center container mx-auto gap-2 p-5 '>
            <div className='flex justify-center flex-col text-start gap-5'>
                <h1 className='text-4xl font-bold'>Read top articles from <br/>health experts</h1>
                <p>Health articles that keep you informed about good health practices and achieve your goals.</p>
                <button className='bg-nevyBlue  text-white p-2 md:w-2/5 w-full rounded-lg flex items-center justify-center cursor-pointer'>See all articles</button>
            </div>
            <div className='flex justify-center flex-col'>
                <div className='flex flex-col md:flex-row gap-3 justify-center '>
                    <img src={article1} alt="" className=' md:w-3/5 w-full' />
                    <div className='flex flex-col justify-center text-start'>

                        <p className='text-yellow font-bold'>Coronavirus</p>
                        <h5 className='font-bold'>12 Coronavirus Myths and Facts That You Should Be Aware Of</h5>
                        <p>Dr. Diana Borgio</p>
                    </div>
                </div>
                <div className='flex gap-3  flex-col md:flex-row'>
                    <img src={article2} alt=""  className='md:w-3/5 w-full'/>
                    <div className='flex flex-col justify-center text-start'>

                        <p className='text-yellow font-bold'>Vitamins and Supplements</p>
                        <h5 className='font-bold'>Eating Right to Build Immunity Against Cold and Viral Infections</h5>
                        <p>Dr. Diana Borgio</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Articles