import React from 'react'
import './Section2.css'
import { Link } from 'react-router-dom';

function Section2() {
  return (
    <>
   

<section className='mt-20'>
    <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
            <div className="md:w-7/12 w-full relative">
                <div className=''>

                <img src="/images/Rectangle-14754.png" alt="" />
                </div>
                <div class='flex flex-wrap mt-4'>
            <div class="md:w-3/12 w-full mt-4 px-4">
               <h6 class='text-base leading-tight font-semibold button-bg-color p-3 text-white rounded-xl md:w-60 w-5/5'>24/7 Support<br/> We are always ready to work</h6>
            </div>
            <div class="md:w-9/12 w-full md:px-4 px-0 md:absolute static  left-0 md:left-72 md:top-80 md:mt-0 mt-10">
                <img src="/images/Rectangle-14756.png" alt="" />
            </div>
        </div>
                
            </div>
            <div className="md:w-5/12 w-full md:px-4 px-2">
                <h5 className='text-lg leading-tight font-semibold mt-5 bg-light rounded-xl md:w-3/5 w-full p-2 text-white'>Your Health is our priority</h5>
                <h1 className='mt-5 md:text-3xl text-xl font-bold leading-normal text-left'>We are the best solution <br/>for your <span className='font-bold text-nevyBlue'>Health care</span></h1>
                <p className='mt-4 text-left'>Welcome to Sheikha Fathima Zahara Hospital, where we provide Advanced Clinical Physiotherapy, Allied Therapeutics, and Wellness services with a touch of compassion and understanding. When you step into our facility, you're not just a patient; you become a valued member of our community. We prioritize listening to your concerns, comprehending your needs, and customizing treatments to best suit you. Whether it's physiotherapy or allied therapeutics, we emphasize addressing both your physical and emotional well-being.</p>
                <div className="flex flex-wrap mt-4">
                    <div className="md:w-1/2 w-full pr-2 md:py-0 py-4">
                    <Link to="/specialties">
                        <button className="w-full button-bg-color text-white font-semibold py-2 px-4 rounded-xl hover:bg-blue-600 focus:outline-none focus:shadow-outline">Discover More</button>
                    </Link>
                    </div>
                    <div className="md:w-1/2 w-full pl-2">
                        <button className="w-full bg-nevyBlue text-white font-semibold py-2 px-4 rounded-xl hover:bg-green-600 focus:outline-none focus:shadow-outline">Book Appointment</button>
                    </div>
                </div>                
            </div>
        </div>
       
    </div>
</section>


    </>
  )
}

export default Section2