import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <>
        {/* <section className=' contact-form'>
        <div className="container my-5  contact">
      <div className="row details">
        <div className="col-md-5">
        
          <h2>Subscribe to our Newsletter</h2>
          <h5>Subscribe to our Newsletter</h5>
          
<div className="input-group mt-4">
  <input
    type="email"
    className="form-control"
    id="emailNewsletter"
    aria-describedby="emailHelp"
    placeholder="Enter email"
  />
  <div className="input-group-append">
    <button type="submit" className="btn btn-custom">
      <i className="fas fa-paper-plane"></i>
    </button>
  </div>
</div>




        </div>
        
        
        <div className="col-md-2 d-flex align-items-center justify-content-center">
          <div className="vertical-lines">
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>

        <div className="col-md-5 contactform">
         
          <h2>Contact Us</h2>
          <h5>To get latest News and Updates</h5>
          
          <div className='email mt-3'>
            <span className='icons'>
              <i className="fas fa-envelope"></i>
            </span>
            <a href="mailto:Contact@sheikhafathimahospital.com"> Contact@sheikhafthiahospital.com</a>
          </div>
          <div className='phone mt-3'>
            <span className='icons'>
              <i className="fas fa-phone-alt"></i>
            </span>
            
            <a href="tel:+9392691924"> +91-9392691924</a>
          </div>
        </div>
      </div>
    </div>
    
        </section>
        <section>
        <footer className="bg text-light py-4">
  <div className="container">
    <div className="row footer1">
        
        <div className="col-md-4">
            <div className="navbar-logo">
                <img src="/images/logo.png" alt="Logo" />
            </div>
            <p className='para mt-3'>A creation of Sheikha Fathima Zahara Hospital, located at Banjara Hills, Road No. 12, HDFC Bank 2nd Floor</p>
            <h4 className='quick-links mt-3'>Follow Us</h4>
            <div className="social-icons">
                <a href="#" class="social-icon"><i class="fab fa-youtube"></i></a>
                <a href="#" class="social-icon"><i class="fab fa-twitter"></i></a>
                <a href="#" class="social-icon"><i class="fab fa-instagram"></i></a>
            </div>

        </div>
        <div className="col-md-2">
            <h5 className='quick-links'>Quick Links</h5>
            <ul className="list-unstyled">
            <li><a href="#">Home</a></li>
            <li><a href="#">Blogs</a></li>
            <li><a href="#">About Us</a></li>
            <li><a href="#">FAQs</a></li>
            <li><a href="#">Return Refund Policy</a></li>
            <li><a href="#">Sitemap</a></li>
            </ul>
        </div>
        <div className="col-md-2">
            <h5 className='quick-links'>Legal</h5>
            <ul className="list-unstyled">
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms and Conditions</a></li>
            
            </ul>
        </div>
        <div className="col-md-2">
            <h5 className='quick-links'>Support</h5>
            <ul className="list-unstyled">
            <li>Sheikha@gmail.com</li>
            
            </ul>
        </div>
        <div className="col-md-2">
            <h5 className='quick-links'>Our Branch</h5>
            <ul className="list-unstyled">
            <li><a href="#">Home</a></li>
            <li><a href="#">Blogs</a></li>
            <li><a href="#">About Us</a></li>
            <li><a href="#">FAQs</a></li>
            <li><a href="#">Return Refund Policy</a></li>
            <li><a href="#">Sitemap</a></li>
            </ul>
        </div>
    </div>
        <hr/>
        <div className="row">
        <div className="col-md-12 text-center">
            <p>Copyrights &copy; 2024 All Rights Reserved by Sheikha Fathima Zahara Hospitals</p>
            
        </div>
        </div>
  </div>
  
    
            </footer>
        </section> */}
    </>
  )
}

export default Contact