import React from 'react'
import line1 from '../../img/home/Line1.png'
import line2 from '../../img/home/Line2.png'

function Departments() {
  return (
    <>
        <div className='bg-opacBlue'>
            <div className='container mx-auto p-5 flex flex-col gap-10' >
                <div  className=''>
                    <h1 className='text-3xl font-bold text-nevyBlue underline underline-offset-2'><span className='text-yellow font-bold underline ' >Medical</span> Departments</h1>
                    
                </div>
                <div className='flex justify-center gap-7 md:flex-row flex-col md:p-0 p-3'>
                    <div className=' '>
                        <ul className='list-disc text-start flex flex-col gap-1 '>
                            <li>Anesthesiology Department</li>
                            <li>Audiology Department</li>
                            <li>Breast Surgery Department</li>
                            <li>Cardiology Center of Excellence</li>
                            <li>Dental Department</li>
                            <li>Dermatology & Aesthetics Department</li>
                            <li>Diet & Nutrition Department</li>
                            <li>Emergency Department</li>
                            <li>Endocrinology Department</li>
                            <li>ENT (Ear, Nose & Throat) Department</li>
                            
                        </ul>
                    </div>
                    <div>
                        <ul className='list-disc text-start flex flex-col gap-1 '>
                            <li>Family Medicine Department</li>
                            <li>Fetal Maternal Medicine Center of Excellence</li>
                            <li>Gastroenterology Department</li>
                            <li>General Practice Department</li>
                            <li>General Surgery Center of Excellence</li>
                            <li>Genetics Clinic</li>
                            <li>Homeopathy Department</li>
                            <li>ICU (Intensive Care Unit) Department</li>
                            <li>Infectious Diseases Department</li>
                            <li>Internal Medicine Department</li>
                        </ul>
                    </div>
                    <div>
                        <ul className='list-disc text-start flex flex-col gap-1 '>
                            <li>IVF Department</li>
                            <li>Laboratory / Pathology Department</li>
                            <li>Nephrology Department</li>
                            <li>Neurology Center of Excellence</li>
                            <li>Neurosurgery Department</li>
                            <li>Obstetrics and Gynaecology Center of Excellence</li>
                            <li>Occupational Therapy</li>
                            <li>Oncology Department</li>
                            <li>Oral & Maxillofacial Surgery Center of Excellenc</li>
                            <li>Orthopedic Center of Excellence</li>
                        </ul>
                    </div>
                    <div>
                        <ul className='list-disc text-start flex flex-col gap-1 '>
                            <li>Paediatric Center of Excellence</li>
                            <li>Pain Medicine Department</li>
                            <li>Pediatric Surgery Department</li>
                            <li>Physiotherapy and Rehabilitation Department</li>
                            <li>Plastic Surgery Department</li>
                            <li>Psychiatry Department</li>
                            <li>Pulmonology Department</li>
                            <li>Radiology and Imaging Department</li>
                            <li>Rheumatology Department</li>
                            <li>Speech (Pathology) Therapy</li>
                            <li>Urology Department</li>
                            <li>Vascular Surgery Department</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Departments