import React, { useState } from 'react'
import logo from "../../img/landingpage/logo.png"
import { Link } from 'react-router-dom';
import landing from "../../img/landingpage/landing.png"
import { RiArrowDropDownLine } from "react-icons/ri";
import { Transition } from '@headlessui/react';
import { CiLocationOn } from "react-icons/ci";


export default function Section2() {
    // const [branch, setBranch] = useState('')
    const [isOpen, setIsOpen] = useState(false);
    const [opening, setOpening] = useState(false);



    const toggleDropdown = () => {
      setIsOpen(!isOpen);
      setOpening(false);
    };
    const toggleDropdown2 = () => {
      setIsOpen(false);
      setOpening(!opening);
      };

      const closeAll = () => {
      setIsOpen(false);
      setOpening(false);
      };
    const updateBranch =(value)=>{
        localStorage.setItem("branch",value)
    }
   
  return (
    <div  style={{backgroundImage: `url(${landing})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height:'100vh',
    // backgroundSize: 'contain',
    }} className='' >
      <section  className='flex  h-full  flex-col items-center justify-start pt-2 gap-10'>
               <div className="flex flex-col items-center justify-center gap-4">
                <img src={logo} alt='' className='h-20'/>
                <h4 className='font-bold md:text-3xl text-2xl text-nevyBlue'>SHEIKHA FATHIMA ZAHARA HOSPITALS</h4>
                </div>
                <div className=" px-2 relative flex flex-col  items-start md:w-1/3 w-full gap-4">
                    <h3 className='md:text-4xl text-2xl text-yellow font-bold text-start'>OUR BRANCHES</h3>
                    <div className="flex flex-col md:flex-row gap-4 md:gap-0 w-full justify-between md:items-center ">
                    <div className="flex  ">
      <h4  onClick={toggleDropdown} className=' text-2xl bg-yellow text-white p-2  rounded-2xl flex items-center justify-center cursor-pointer '><CiLocationOn />Opened<RiArrowDropDownLine /></h4>   
   <div  className={`absolute top-32 md:top-24 md:mt-3 left-0 transition-all ${isOpen ? 'block' : 'hidden'}`}>
   <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
   <ul className='px-2  text-left shadow-md  text-black list-none bg-yellow  w-64 p-4 rounded-bl-lg rounded-br-lg flex flex-col gap-2'>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg ' onClick={()=>{updateBranch("vijay nagar")}} >Vijay Nagar</li>
            </Link>
            <Link to='/home'>

                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Banjara Hills")}}>Banjara Hills</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("karvan")}}>karvan</li>
            </Link>
           
          
        </ul>
        </Transition>
   </div>
   </div>
   <div className="flex ">
      <h4  onClick={toggleDropdown2} className='text-2xl bg-nevyBlue  text-white p-2  rounded-2xl flex items-center justify-center cursor-pointer '><CiLocationOn />Opening Soon<RiArrowDropDownLine /></h4>
   
   <div  className={`absolute top-52 md:top-24 md:mt-3 rigth-0 transition-all ${opening ? 'block' : 'hidden'}`}>
   <Transition
          show={opening}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
   <ul className=' h-[300px] px-2 w-max   text-left shadow-md text-black list-none bg-nevyBlue  p-4 rounded-bl-lg rounded-br-lg flex flex-wrap flex-col gap-1'>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("charminar")}} >charminar</li>
            </Link>
            <Link to='/home'>

                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Malakpet")}}>Malakpet</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Langer Houz")}}>Langer Houz</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Mahdi Colony")}}>Mahdi Colony</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Dar shifa charminar")}}>Dar shifa charminar</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("kalapathar")}}>kalapathar</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Kishan bagh")}}>Kishan bagh</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("secunderabad")}}>secunderabad</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("First Lancer Banjara Hills")}}>First Lancer Banjara Hills</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("abids Hospital")}}>abids Hospital</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("chanchalguda")}}>chanchalguda</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("Attapur Pillar No-120")}}>Attapur Pillar No-120</li>
            </Link>
            <Link to='/home'>
                <li className='bg-white p-1 rounded-lg' onClick={()=>{updateBranch("mehdipatnam")}}>mehdipatnam</li>
            </Link>

        </ul>
        </Transition>
   </div>
   </div>

                    </div>
                    <h1 className='text-2xl  text-start bg-footerOpac text-nevyBlue py-0 md:py-5'>Your Wellness, Our Priority, Welcome to our Hospitals</h1>

                </div>
            {/* <div className="flex flex-col md:flex-row flex-wrap">
    <div className="flex-1 px-12 md:py-8 ">
        <h1 className='underline text-xl font-bold text-left py-5'>Hospitals</h1>
        <ul className=' text-left  text-nevyBlue list-none'>
            <li>Piller No 120- Hospital</li>
            <li>Habits - Hospital</li>
        </ul>
    </div>
    <div className="flex-1 px-12 md:py-8 ">
        <h1 className='underline text-xl font-bold text-left py-5'>Clinics</h1>
        <ul className=' text-left  text-nevyBlue list-none'>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("vijay nagar")}} >Vijay Nagar</li>
            </Link>
            <Link to='/home'>

                <li onClick={()=>{updateBranch("tolichowki")}}>tolichowki</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("chanchalguda")}}>chanchalguda</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Mahdi Colony")}}>Mahdi Colony</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("mehdipatnam")}}>mehdipatnam</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Kishan bagh")}}>Kishan bagh</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Dar shifa Charminar")}}>Dar shifa Charminar</li>
            </Link>


        </ul>
    </div>
    <div className="flex-1 px-12 md:py-8">
        <ul className=' text-left list-none text-nevyBlue md:py-10 gap-10'>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Banjara Hills")}}>Banjara Hills</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Charminar")}}>Charminar</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Carvan")}}>Carvan</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Langer House")}}>Langer House</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("First Lancer")}}>First Lancer</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("Malkapet")}}>Malkapet</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("secunderabad")}}>secunderabad</li>
            </Link>
            <Link to='/home'>
                <li onClick={()=>{updateBranch("kalapathar")}}>kalapathar</li>
            </Link>

        </ul>
    </div>
    
</div>
    */}
      </section>
    </div>
  )
}
