import React,{useState,useEffect} from 'react'
import bannertwo from "../../img/home/bannertwo.png"
import bannerthree from "../../img/home/bannerthree.png"
import hero from "../../img/home/hero.png"
import hero3 from "../../img/home/hero3.png"
import bannerfour from "../../img/home/bannerfour.png"
import msg from "../../img/home/msg.png"
import banner from "../../img/home/fbanner.png"
import { CiLocationOn } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscNotebook } from "react-icons/vsc";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Banner from './Banner'
import BannerTwo from './Bannertwo'
import BannerThree from './BannerThree'
import BannerFour from './BannerFour'
import BannerFive from './BannerFive'
function Herosection() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        afterChange: (index) => setCurrentSlide(index),
        // nextArrow: <CustomNextArrow />,
        // prevArrow: <CustomPrevArrow />,
      };

      
  const [selectedBranch, setSelectedBranch] = useState('');
  const [isOpen, setIsOpen] = useState(false);



  
  const updateBranch =(value)=>{
    localStorage.setItem("branch",value)
}

useEffect(() => {
   
  const storedBranch = localStorage.getItem('branch');
  setSelectedBranch(storedBranch);
}, [updateBranch]);

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};


  return (
         <div className=" ">    
        <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
           {/* <Banner/>  */}
           <BannerTwo/>
           <BannerThree/>
           <BannerFour/>
           <BannerFive/>
               </Carousel>
</div> 
  )
}

export default Herosection