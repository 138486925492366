import React from 'react'
import service1 from "../../img/specialties/service1.png"
import service2 from "../../img/specialties/service2.png"
import service3 from "../../img/specialties/service3.png"
import service4 from "../../img/specialties/service4.png"
import service5 from "../../img/specialties/service5.png"
import service6 from "../../img/specialties/service6.png"
import service7 from "../../img/specialties/service7.png"
import service8 from "../../img/specialties/service8.png"
import service9 from "../../img/specialties/service9.png"
import service10 from "../../img/specialties/service10.png"
import service11 from "../../img/specialties/service11.png"
import service12 from "../../img/specialties/service12.png"
import{Link} from "react-router-dom"


function Section2() {
  return (
    <> 


<section className='pt-6 container mx-auto md:container md:mx-auto'>
    <h1 className='md:text-3xl text-2xl mt-5'>CENTERS OF EXCELLENCE</h1>
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-10 md:p-0 p-5'>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden hover:border-2  border-yellow-500 '>
                <Link to='/neurology'>
                    <img src={service1} alt='' className='mx-auto w-2/5 pt-4' />
                    <h4 className='pt-5'>Dentistry</h4>
                </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500' >
            <Link to='/neurology'>
                <img src={service2} alt=''  className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>Gastroenterology</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service3} alt='' className='mx-auto w-2/5 pt-4' />
                <h4 className='pt-5'>Cardiology</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service4} alt=''  className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>Liver</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service5} alt='' className='mx-auto w-2/5 pt-4' />
                <h4 className='pt-5'>CT Surgery</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service6} alt='' className='mx-auto w-2/5 pt-4'/>
                <h4>Mother & Child</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service7} alt='' className='mx-auto w-2/5 pt-4' />
                <h4 className='pt-5'>Physiotherapy</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service8} alt='' className='mx-auto w-2/5 pt-4' />
                <h4 className='pt-5'>Neurosciences</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service9} alt=''  className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>Orthopaedics</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service10} alt='' className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>Nephrology</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500 '>
            <Link to='/neurology'>
                <img src={service11} alt='' className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>Spine Surgery</h4>
            </Link>
            </div>
            <div className='  bg-white rounded-lg shadow-xl overflow-hidden p-5 hover:border-2  border-yellow-500'>
            <Link to='/neurology'>
                <img src={service12} alt='' className='mx-auto w-2/5 pt-4'/>
                <h4 className='pt-5'>ENT</h4>
            </Link>
            </div>
        
    </div>
</section>

      
    </>
  )
}

export default Section2