import React from 'react'
import msg from "../../img/home/msg.png"
const BannerFive = () => {
  return (

                <div className='px-2 py-4 bgcolor h-full flex md:flex-row flex-col items-center justify-start md:justify-center gap-6 md:p-8 p-1'  >
                        <img src={msg} alt=""className=' h-150 w-100 md:h-[380px] md:w-[450px]    ' />
                    <div className='md:text-start text-center flex flex-col gap-6'>
                        <h1 className='md:text-4xl text-3xl font-bold text-white'>Your Health Is Our Priority</h1>
                        <p className='text-white  '>
                        We have a best doctors entire team from Hyderabad world class treatment in a linear and advance surgeries,ethical practices with a such experience candidates. We provide care, concern and health in a affordable prices.
                        </p>
                        <div className="flex flex-row  gap-5  items-center justify-center ">
                            <button className='border border-white p-2 rounded-3xl text-white '>Read More</button>       
                            <button className='bg-nevyBlue p-2 rounded-3xl text-white'>Book an Appointment</button>       
                        </div>
                    </div>
                </div>

  )
}

export default BannerFive
