import React from 'react'
import { IoIosSend } from "react-icons/io";
import { BiPhoneCall } from "react-icons/bi";
import { IoMailOutline } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logo from "../img/landingpage/logo.png"
import { Link } from 'react-router-dom';
import footer from "../img/footer/footer.png"
const Footer = () => {
  return (
    <div className=' flex flex-col items-center justify-center gap-4 bg-white text-white relative mt-[150px] '>
      <div className="bg-yellow  md:w-3/4 w-full flex flex-col md:flex-row justify-between items-center md:items-start md:p-10 p-2 rounded md:absolute static top-[-120px] ">

        <div className="flex flex-col items-center md:items-start gap-2">
          {/* <h3 className='text-2xl'>Subscribe to our Newsletter</h3> */}
          <p className='text-xl text-start'>Join Sheikha Hospitals Group <br /> Newsletter and stay updated with our <br /> latest news and offers</p>
          <div className="flex justify-center ">
          <input type="text" placeholder='enter your email' name="" id="" className='h-10 w-2/3 md:w-72 pl-2 border-none' />
          <p className='border flex items-center w-12 justify-center text-2xl text-white'><IoIosSend /></p>
          </div>
        </div>
        <div>
            <div className=" flex-col align-items-center justify-content-center h-40 vertical md:flex hidden">
              <div className="vertical-lines">
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
              </div>
            </div>
            
        </div>
        <div className="flex flex-col items-center md:items-start gap-2  md:w-auto w-full mt-10 md:mt-0">
          <h4 className='text-2xl md:pl-0 pl-5'>Contact Us</h4>
          <p className='md:text-xl text-xs md:pl-0 pl-2'>To get latest News and Updates</p>
          <p className='flex items-center gap-2 text-lg md:pl-0 pl-5'><BiPhoneCall />  <a href="tel:+91-9392691924">+91-9392691924</a></p>
          <a href="mailto:Contact@sheikhafathimahospital.com"><p className='flex items-center md:gap-2 gap-1 text-lg  email-footer'><IoMailOutline />Contact@sheikhafathimahospital.com</p></a>
        </div>
      </div>
      <div style={{backgroundImage: `url(${footer})`,
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'start',
    // height:'100vh',
    backgroundPosition: 'right bottom', 
    backgroundSize: 'contain',
    }} className="bg-violet text-nevyBlue w-full pt-5  md:mt-0 mt-0 ">
      <div  className="  flex flex-col  pt-0 md:pt-0 md:flex-row items-end justify-center text-center md:text-start gap-3 md:gap-20 ">
       <div className=" w-full md:w-1/4 flex flex-col gap-4 md:pt-32">
        <div className="flex items-center justify-center">
        <img src={logo} alt="" className='h-20  object-contain p-2'  />

        <h4 className='font-bold text-xl text-start '>SHEIKHA FATHIMA ZAHARA HOSPITALS</h4>

        </div>
        <p>A creation of Sheikha Fathima Zahara Hospital, located at Banjara Hills, Road No. 12, HDFC Bank 2nd Floor</p>
     <p className='text-xl underline'>Follow Us</p>
     <div className="flex justify-center md:justify-start gap-3 ">
          <FaLinkedin className='text-2xl cursor-pointer' />
          <FaFacebook className='text-2xl cursor-pointer'/>
          <FaXTwitter className='text-2xl cursor-pointer'/>
          </div>
       </div>
       <div className="flex flex-col md:items-start  items-center w-full  md:w-44">

             <ul className='bg-footerOpac'>
          <p className='font-bold'>Quick Links</p>
          <Link to='/'><li>Home</li></Link>
          <Link to='/aboutus'><li>About Us</li></Link>
          <Link to='/specialties'><li>Specialities </li></Link>
          <Link to='/contactUs'><li>Contact Us</li></Link>

          <li>Return Refund Policy</li>
        </ul>
           
        <ul className='bg-footerOpac'>
          <p className='font-bold'>Legal</p>
          <li>Terms and Conditions</li>
          <li>Terms and Conditions</li>
        </ul>
       </div>

        <ul className=' w-full md:w-72 bg-footerOpac'>
          <p className='font-bold '>Support</p>
          <a href="mailto:Contact@sheikhafathimahospital.com"> <li className=' email-footer'>Contact@sheikhafathimahospital.com</li></a>
        </ul>

        {/* <img src={footer} className='h-64' alt="" /> */}
      </div>
      <div className="border-t-2 py-2 mt-2 bg-footerOpac">
        <p>Copyrights © 2024 All Rights Reserved by Sheikha Fathima Zahara Hospitals</p>
      </div>
      </div>

    </div>
  )
}

export default Footer
