import React from 'react'
import excellence1 from '../../img/home/excellence1.png'
import excellence2 from '../../img/home/excellence2.png'
import excellence3 from '../../img/home/excellence3.png'
import excellence4 from '../../img/home/excellence4.png'
import excellence6 from '../../img/home/excellence6.png'
import excellence5 from '../../img/home/excellence5.png'
import excellence7 from '../../img/home/excellence7.png'
import excellence8 from '../../img/home/excellence8.png'

function Excellence() {
  return (
    <>
        <div className='container mx-auto flex flex-col gap-8 px-5'>
            <div className='items-center flex flex-col gap-5 '>
                
                <h1 className='text-yellow font-bold text-3xl underline '><span className='text-nevyBlue font-bold underline '>Our</span> Centre of Excellence</h1>
                <p>To operate as a world-class heart hospital, we incorporate the latest technological advances and<br/> ethical practices to provide quality heart care at a reasonable cost.</p>
            </div>
            <div className=' grid md:grid-cols-4 gap-8 grid-cols-1 md:p-0 p-8  '>
                {/* <div className='  shadow-xl rounded-b-xl relative overflow-hidden items-center'>
                    <img src={excellence1} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl"  />
                    <p className='font-bold p-2' >Cardiology Center of Excellence</p>
                </div> */}
                <div className='shadow-xl rounded-b-xl relative overflow-hidden items-center'>
    <img 
        src={excellence1} 
        alt="" 
        className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500 "  
    />
    <p className='font-bold p-2'>Cardiology Center of Excellence</p>
</div>

        
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence2} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p className='font-bold p-2'>Fetal Maternal Medicine Center of Excellence</p>
                </div>
                
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence3} alt=""  className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl"/>
                    <p className='font-bold p-2'>General Surgery Center of Excellence</p>
                </div>
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence4} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p className='font-bold p-2'>Neurology Center of <br/> Excellence</p>
                </div>
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence5} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p className='font-bold p-2'>Obstetrics and Gynaecology Center of Excellence</p>
                </div>
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden' >
                    <img src={excellence6} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p className='font-bold p-2'>Oral & Maxillofacial Surgery Center of Excellence</p>
                </div>
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence7} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p  className='font-bold p-2'>Orthopedic Center of Excellence</p>
                </div>
                <div className='  shadow-xl rounded-b-xl items-center relative overflow-hidden'>
                    <img src={excellence8} alt="" className="w-full h-auto transition-transform transform-gpu hover:scale-105 duration-500  hover:rounded-t-xl" />
                    <p className='font-bold p-2'>Paediatric Center of <br/>Excellence</p>
                </div>

            </div>
        </div>
    </>
  )
}

export default Excellence