import React,{useState} from 'react'

import testimonial from '../../img/home/testimonial.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Testimonials() {
    
const [currentSlide, setCurrentSlide] = useState(0);

// const CustomNextArrow = ({ className, onClick }) => (
//   <button
//     className={`${className} bg-green text-nevyBlue p-2 rounded`}
//     style={{ right: '100px' }}
//     onClick={onClick}
//   >
//     Next
//   </button>
// );

// const CustomPrevArrow = ({ className, onClick }) => (
//   <button
//     className={`${className} bg-green text-nevyBlue p-2 rounded`}
//     style={{ left: '10px' }}
//     onClick={onClick}
//   >
//     Prev
//   </button>
// );

const settings = {
  dots: true,
// dots: false,

  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  afterChange: (index) => setCurrentSlide(index),
//   nextArrow: <CustomNextArrow />,
//   prevArrow: <CustomPrevArrow />,
};

  return (
    <>
       <div  className="container mx-auto items-center flex flex-col justify-center gap-8 p-5">
            <h1 className='text-3xl font-bold'>What our Patient Says</h1>
        <Slider {...settings} className='scroll w-3/5 p-5 '>
                <div className='  flex flex-col justify-center items-center p-5 gap-4 shadow-xl rounded-xl custom-border'>
                    <p>Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also,Doctor's feedback mechanism is good and describes all the basics in a good way</p>
                    <div className='flex justify-center items-center gap-4'>

                        <img src={testimonial} alt="" />
                        <p>Riya leno</p>
                    </div>
                </div>
                <div className='  flex flex-col justify-center items-center p-5 gap-4 shadow-xl rounded-xl custom-border '>
                    <p>Very good app. Well thought out about booking/rescheduling/canceling an appointment. Also,Doctor's feedback mechanism is good and describes all the basics in a good way</p>
                    <div className='flex justify-center items-center gap-4'>

                        <img src={testimonial} alt="" />
                        <p>Riya leno</p>
                    </div>
                </div>
            </Slider>

        </div>
    </>
  )
}

export default Testimonials