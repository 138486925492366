import React from 'react'
import './Section3.css'

function Section3() {
  return (
    <>
     

<section className='pt-20'>
  <div className="container mx-auto px-4">
    <div className="">
      <div className="">
        <div className="flex flex-wrap -mx-4">
          {/* Card 1 */}
          <div className="w-full md:w-1/2 px-4 mb-6 ">
            <div className="flex flex-col md:flex-row bg-white rounded-lg shadow overflow-hidden hover:border-2 rounded-lg border-yellow-500">
              <div className="md:w-2/5">
                <img src="/images/image1.png" alt="Card 1" className="object-cover w-full h-full" />
              </div>
              <div className="md:w-3/5 p-4">
                <h5 className="text-2xl font-bold mb-2 text-nevyBlue text-left">Our Approach</h5>
                <p className="text-gray-700 text-sm text-left">
                At Sheikha Fathima Zahara Hospital, our approach revolves around community integration and personalized care. When you visit us, you become an integral part of our community, not just a patient. 
                </p>
              </div>
            </div>
          </div>
          {/* Card 2 */}
          <div className="w-full md:w-1/2 px-4 mb-6">
            <div className="flex flex-col md:flex-row bg-white rounded-lg shadow overflow-hidden hover:border-2 rounded-lg border-yellow-500">
              <div className="md:w-2/5">
                <img src="/images/Frame2.png" alt="Card 2" className="object-cover w-full h-full" />
              </div>
              <div className="md:w-3/5 p-4">
                <h5 className="text-2xl font-bold mb-2 text-nevyBlue p-3 text-left">Evidence-based Care</h5>
                <p className="text-gray-700 text-sm text-left">
                We firmly believe in evidence-based care, providing regular prognosis reports to empower you in tracking your progress and understanding your healing journey.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-4 mt-6">
          {/* Card 3 */}
          <div className="w-full md:w-1/2 px-4 mb-6">
            <div className="flex flex-col md:flex-row bg-white rounded-lg shadow overflow-hidden hover:border-2 rounded-lg border-yellow-500">
              <div className="md:w-2/5">
                <img src="/images/Frame1.png" alt="Card 3" className="object-cover w-full h-full" />
              </div>
              <div className="md:w-3/5 p-4">
                <h5 className="text-2xl font-bold mb-2 text-nevyBlue text-left">Healing With Heart</h5>
                <p className="text-gray-700 text-sm text-left">
                Compassion forms the heart of our healing process. We cultivate a nurturing environment where you feel supported throughout your recovery, whether it involves injury rehabilitation, chronic pain management, or enhancing overall health.
                </p>
              </div>
            </div>
          </div>
          {/* Card 4 */}
          <div className="w-full md:w-1/2 px-4 mb-6">
            <div className="flex flex-col md:flex-row bg-white rounded-lg shadow overflow-hidden hover:border-2 rounded-lg border-yellow-500">
              <div className="md:w-2/5">
                <img src="/images/image.png" alt="Card 4" className="object-cover w-full h-full" />
              </div>
              <div className="md:w-3/5 p-4">
                <h5 className="text-2xl font-bold mb-2 text-nevyBlue text-left">Collaboration and Support</h5>
                <p className="text-gray-700 text-sm text-left ">
                We recognize that healing requires a collaborative effort. Thus, we work closely with medical specialists to ensure you receive comprehensive and top-quality care. Moreover, beyond treatment. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


    </>
  )
}

export default Section3