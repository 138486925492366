import React, {useState,useEffect} from 'react'
import bannerthree from "../../img/home/bannerthree.png"


const BannerThree = () => {

  const [selectedBranch, setSelectedBranch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const updateBranch =(value)=>{
    localStorage.setItem("branch",value)
}

useEffect(() => {
   
  const storedBranch = localStorage.getItem('branch');
  setSelectedBranch(storedBranch);
}, [updateBranch]);

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

return (

        <div className="px-2 py-4 bg-blueopac h-full flex flex-col  md:flex-row md:items-center items-start  md:justify-center justify-start gap-6 md:gap-10">
        <img src={bannerthree}  alt="" className=' h-200 w-250 md:h-[500px] md:w-[200px] object-contain '/>        
        <div  className="flex flex-col items-center md:items-start  justify-start  px-1 md:px-0 md:pt-20 gap-6 text-center md:text-start ">       
            <h2 className='flex md:text-4xl text-3xl  font-bold text-nevyBlue'>Your Health
                Is Our Priority</h2>
            <p className=' px-4 md:px-0 text-center md:text-start'>We have a best doctors entire team from Hyderabad world class treatment in a linear and advance surgeries,ethical practices with a such experience candidates. We provide care, concern and health in a affordable prices.</p>
            <div className="flex flex-row  justify-between gap-5 md:gap-15">
        <button className='border border-nevyBlue p-2 rounded-3xl text-nevyBlue '>Read More</button>       
        <button className='bg-nevyBlue p-2 rounded-3xl text-white'>Book an Appointment</button>       
            </div>
            </div>
            
        </div>
  )
}


export default BannerThree
