import React, {useState,useEffect} from 'react'
import banner from "../../img/home/fbanner.png"
import { CiLocationOn } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscNotebook } from "react-icons/vsc";
import { IoSearchOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import bannerfour from "../../img/home/bannerfour.png"
const BannerFour = () => {

  const [selectedBranch, setSelectedBranch] = useState('');
  const [isOpen, setIsOpen] = useState(false);



  
  const updateBranch =(value)=>{
    localStorage.setItem("branch",value)
}

useEffect(() => {
   
  const storedBranch = localStorage.getItem('branch');
  setSelectedBranch(storedBranch);
}, [updateBranch]);

const toggleDropdown = () => {
  setIsOpen(!isOpen);
};


return (
        <div className="px-2 bg-blueopac h-full flex flex-col  md:flex-row items-center justify-between gap-6 md:gap-10 py-4">
        <img src={bannerfour}  alt="" className=' h-200 w-250 md:h-[500px] md:w-[200px]  object-contain ' />        
        <div  className="flex flex-col items-center md:items-start justify-start  h-full px-1 md:px-0 md:pt-20 gap-6 md:text-start text-center  ">
           <h2 className='text-5xl font-bold text-yellow'>500/-</h2>
            <h2 className='flex md:text-4xl text-3xl   font-bold text-nevyBlue'>Wellness Plan, Access All Treatments for a Year Your Pathway to Affordable Health!</h2>
            {/* <p className='md:w-2/4 px-4 md:px-0 text-end md:text-start'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a</p> */}
            <div className="flex flex-row  justify-between gap-5 md:gap-15">
        <button className='border border-nevyBlue p-2 rounded-3xl text-nevyBlue '>Read More</button>       
        <button className='bg-nevyBlue p-2 rounded-3xl text-white'>Book an Appointment</button>       
            </div>
            </div>
            
        </div>

  )
}

export default BannerFour
