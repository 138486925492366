import React from 'react'

function Section4() {
  return (
    <>
            <section className='mt-5 py-10'>
      

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.351239893869!2d78.45486047420975!3d17.394923602589863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9782569583cd%3A0x4406070d0bdac148!2sSheikha%20Fathima%20Zahara%20Hospital!5e0!3m2!1sen!2sin!4v1712204110801!5m2!1sen!2sin" 
                width="100%" 
                height="450" 
                style={{ border: 0 }} 
                allowFullScreen=""
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">

            </iframe>


    </section>
    </>
  )
}

export default Section4