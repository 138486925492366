import React from 'react'
import contactus from "../../img/contactus/contactus.png"
// import banner from "../../img/home/fbanner.png"




function Section1() {
  return (
    <>
    


    <section className="  bg-cover  bg-no-repeat" style={{ backgroundImage: `url(${contactus})`,height: '400px' }}>
      <h1 className='font-bold text-3xl text-white py-36'>Contact Us</h1>
    </section>

    </>
  )
}

export default Section1